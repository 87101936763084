import React from "react"
import { graphql, Link } from "gatsby"
import tw from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import SEO from "../components/SEO"
import { Layout } from "../components/Layout"
import { SiteMetadata } from "../model/cms"

const Container = tw.div`flex flex-row w-full h-full items-center justify-center`
const NotFoundContent = tw.div``
const NotFoundTitle = tw.h1`text-6xl text-center mb-8`
const NotFoundSubtitle = tw.h2`text-3xl text-center`
const SubtitleLink = tw(Link)`underline hover:text-blue-500`

interface NotFoundPageProps {
    data: {
        site: {
            siteMetadata: SiteMetadata
        },
    }
}


export default function NotFoundPage({ data }: NotFoundPageProps) {
    const { discordUrl } = data.site.siteMetadata

    return (
        <Layout>
            <SEO
                title="Long Rock Labs"
                description="Page not found."
                meta={[]}
            />
            <Container>
                <NotFoundContent>
                    <NotFoundTitle>Oops, page not found</NotFoundTitle>
                    <NotFoundSubtitle>
                        Have a look at <SubtitleLink to="/">our mission</SubtitleLink>, or
	read one of <SubtitleLink to="/articles">our articles</SubtitleLink>.
	    </NotFoundSubtitle>
                </NotFoundContent>
            </Container>
        </Layout>
    )
}


export const pageQuery = graphql`
    {
        site {
            siteMetadata {
                discordUrl
                twitterUrl
            }
        }
    }
`
